import React from "react";
import { Box } from "@chakra-ui/react";
import Background from "./Background";

interface PageWrapperProps {
  children: React.ReactNode; // Przekazujemy wszystkie dzieci jako React.ReactNode
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <Box width="100%" height="100vh" position="relative" overflow="hidden">
      <Background />
      {children}
    </Box>
  );
};

export default PageWrapper;
