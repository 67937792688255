import { Button, ButtonProps, Text, Image, Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";

interface CustomButtonProps extends ButtonProps {
  text: string;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string | number;
  onClick?: () => void;
  logoSrc?: string;
}

export const CustomButton: React.FC<CustomButtonProps> = observer(
  ({
    text,
    onClick,
    backgroundColor = "accent",
    textColor = "background",
    fontSize = { base: "18px", md: "18px" },
    fontWeight = "bold",
    logoSrc,
    ...props
  }) => {
    return (
      <Button
        variant="menu"
        bg={backgroundColor}
        py="20px"
        px={"40px"}
        borderRadius="9px"
        borderColor={backgroundColor}
        onClick={onClick}
        textTransform="none"
        _hover={{}}
        {...props}
      >
        <Box position="relative">
          {logoSrc && (
            <Image
              src={logoSrc}
              alt="Logo"
              boxSize="24px"
              style={{ position: "absolute", top: "50%", right: "120%", transform: "translateY(-50%)" }}
            />
          )}
          <Text fontSize={fontSize} lineHeight={{ base: "20px", md: "20px" }} color={textColor} fontWeight={fontWeight}>
            {text}
          </Text>
        </Box>
      </Button>
    );
  }
);
