import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { Layout } from "../../components/Layout/Layout";
import { withModel } from "../../hocs/withModel";
import { MainViewModel } from "./MainViewModel";
import { brandModel } from "../../models/BrandModel";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";

interface MainViewProps {
  model: MainViewModel;
}

const MainView: React.FC<MainViewProps> = observer(({ model }) => {

  const { keycloak } = useKeycloak();
  const navigate = useNavigate()
  const { currentBrand } = brandModel;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  //testy dla apki blockchain... gdyby komunikat sie wyslal w dobrym czasie(od razu po zaladowaniu apki)
  //to by nie bylo podwojnego menu

  function delayedFunction() {
    console.log('asked to hide menu');
    let frame = document.querySelector('#main-frame') as HTMLIFrameElement | null;
    frame?.contentWindow?.postMessage('hide-menu-bar', currentBrand.app_url);
  }
  useEffect(() => {
    if(!keycloak.authenticated){
      navigate('/login');
    }

      delayedFunction();

  });

  useEffect(() => {

      setTimeout(delayedFunction, 2000)

  }, [currentBrand.app_url]);


  return (
    <Layout>
      <iframe ref={iframeRef} id='main-frame' title={currentBrand.name} width='100%' height='800px' src={currentBrand.app_url}></iframe>
    </Layout>
  );
});

const MainViewWithModel = withModel<MainViewModel>(MainView, MainViewModel);

export default MainViewWithModel;
