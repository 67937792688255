import { VStack, IconButton, Button, HStack, Text } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { USER_MENU_ITEMS } from "../constants";
import { onIconWithListClick } from "../helpers";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { DropdownWrapper } from "./DropdownWrapper";
import { Avatar } from "./Avatar";
import { useKeycloak } from "@react-keycloak/web";

interface UserMenuProps {}

export const UserMenu: React.FC<UserMenuProps> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { keycloak } = useKeycloak();

  const listRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);
  return (
    <VStack>
      <IconButton
        aria-label={"label"}
        icon={<UserIcon fill={isOpen ? "black" : "white"} />}
        onClick={() => onIconWithListClick(setIsOpen, listRef, iconRef)}
        ref={iconRef}
        bg="transparent"
        border={0}
        w="fit-content"
        h="fit-content"
      />
      {isOpen && (
        <DropdownWrapper listRef={listRef} pl="40px">
          <HStack mb="30px" gap="20px">
            <Avatar image="/uzytkownik-ikona.svg" />
            <VStack align="flex-start" gap="0">
              <Text color="white" fontWeight="bold">
                {keycloak?.tokenParsed?.name || ''}
              </Text>
              <Text color="white">{keycloak?.tokenParsed?.preferred_username || ''}</Text>
            </VStack>
          </HStack>
          <VStack gap="14px" ml="20px">
            {/**USER_MENU_ITEMS.map(menuItem => (
              <Button variant="menu" key={menuItem.href} as="a" href={menuItem.href} color='gray' bg="primary">
                {menuItem.label}
              </Button>
            ))*/}
            <Button mt="28px" variant="menu" color="primary" bg="black" borderWidth="2px" borderColor="primary" onClick={()=>keycloak.logout()}>
              Wyloguj
            </Button>
          </VStack>
        </DropdownWrapper>
      )}
    </VStack>
  );
};
