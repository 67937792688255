import { Box, Image } from "@chakra-ui/react";
import React from "react";

interface AvatarProps {
  image: string;
}

export const Avatar: React.FC<AvatarProps> = ({ image }) => {
  return (
    <Box bg="primary" borderRadius="50%" borderColor="white" borderWidth="2px" overflow="hidden" w="60px" h="60px">
      <Image src={image} />
    </Box>
  );
};
