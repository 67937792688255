import { extendTheme } from "@chakra-ui/react";
import { BREAKPOINTS } from "../utils/constants";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";

export const brandColors = {
  ivotingFree: "#16BECF",
  ivotingBusiness: "#EBB134",
  ivotingNgo: "#237760",
  ivotingUniversity: "#BC1F4C",
  ivotingGov: "#977460",
  ivotingPublicOpinion: "#912690"
};

export const getConfig = (primaryColor: string) => ({
  breakpoints: {
    sm: `${BREAKPOINTS.MOBILE}px`,
    md: `${BREAKPOINTS.MD}px`,
    lg: `${BREAKPOINTS.LG}px`,
    xl: `${BREAKPOINTS.XL}px`,
    "2xl": `${BREAKPOINTS.FULL_HD}px`
  },
  fonts: {
    heading:
      "Open Sans,Averta,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    body: "Open Sans,Averta,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"
  },
  colors: {
    primary: primaryColor,
    divider: "#707070"
  },
  components: {
    Button: {
      variants: {
        menu: {
          color: "white",
          bg: "primary",
          w: "100%",
          py: "24px",
          px: "80px",
          borderRadius: "15px",
          textTransform: "uppercase",
          fontSize: "16px",
          lineHeight: "22px",
          fontWeight: "bold"
        }
      }
    }
  },
  shadows: {
    standard: "0px 6px 6px #00000029;"
  }
});
const getTheme = (primaryColor: string) => extendTheme(getConfig(primaryColor));

export default getTheme;
