import { createBrowserRouter } from "react-router-dom";
import HomeView from "../views/HomeView/HomeView";
import MainView from "../views/MainView/MainView";
import { LoginView } from "../views/LoginView/LoginView";

const RouterConfig = () => {

  return createBrowserRouter([
    {
      path: "/login",
      element: <LoginView />
    },
    {
      path: "/",
      element: <MainView />
    }
  ]);
};

// Export the result of the RouterConfig function component
export const router = RouterConfig();
