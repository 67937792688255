import { ToastType } from "../types/general";

export abstract class Model {
  abstract onInit(): Promise<void>;
  constructor() {}

  showGenericErrorToast(toast: ToastType, customMessage?: string) {
    toast({
      title: customMessage ?? "Coś poszło nie tak, spróbuj ponownie później",
      status: "error",
      isClosable: true
    });
  }
}
