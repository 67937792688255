import { VStack, HStack, Image, Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import RectangleImage from "../../../assets/Rectangle-45.svg";
import LogoImageBC from "../../../assets/LOGO_IVOTING_BLOCKCHAIN.svg";
import LogoImageBUS from "../../../assets/LOGO_IVOTING_BUSINESS.svg";
import NavigateImage from "../../../assets/Icon-material-navigate-next.svg";
import { brandModel } from "../../../models/BrandModel";
import { BrandMenuItemType, BRAND_MENU_ITEMS } from "../../../constants/brand";
interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {

  const brand_logos = [
    {
      logo: LogoImageBUS,
    },
    {
      logo: LogoImageBC,
    },
  ]

  const { setBrand, currentBrand } = brandModel;
  const [ pickBrand, setPickBrand ] = useState(0);


  const HandleBrandPick = () => {

     if(pickBrand+1 < BRAND_MENU_ITEMS.length){
       setPickBrand(pickBrand+1)
       setBrand(BRAND_MENU_ITEMS[pickBrand])

     }
  }

  const HandleBrandLower = () => {

     if(pickBrand-1 >= 0){
       setPickBrand(pickBrand-1)
       setBrand(BRAND_MENU_ITEMS[pickBrand])

    }
  }


  return (
    <>

    <HStack as="button" gap="16px" py="12px" height="200" style={{margin: 'auto'}}>
      <Image src={NavigateImage} height='50' onClick={HandleBrandLower}  style={{transform: 'scaleX(-1)', paddingLeft: '20px'}} />
      <Image src={brand_logos[pickBrand].logo} alt="sygnet" style={{ maxWidth: '500px'}}/>
      <Image src={NavigateImage} height='50' onClick={HandleBrandPick} style={{ paddingLeft: '20px'}} />
    </HStack>


    </>
  );

  /**<VStack alignItems="center">
    <Box position="relative">
      <svg xmlns="http://www.w3.org/2000/svg" width="630" height="200">
        {" "}

        <image href={RectangleImage} width="100%" height="100%" />
        <image href={NavigateImage} width="5%" height="15%" x="-18%" y="47%" style={{transform: 'scaleX(-1)'}}/>{" "}
        <image href={currentBrand.logo} width="40%" height="70%" x="30%" y="20%" transform="translate(-15%)" />{" "}<Box>test</Box>
        <image href={NavigateImage} width="5%" height="15%" x="82%" y="47%" />{" "}
      </svg>
    </Box>
  </VStack>*/
};
