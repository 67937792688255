export const BREAKPOINTS = {
  MOBILE: 768,
  MD: 960,
  LG: 1200,
  XL: 1600,
  FULL_HD: 1920
};

export const LAYOUT_CONTENT_WIDTHS = {
  md: "900px",
  lg: "1000px",
  xl: "1440px"
};
