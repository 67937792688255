import { VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { CustomButton } from "../../../components/CustomBotton/CustomButton";
import IconImage from "../../../assets/logo_cyber_id.svg";
import { useKeycloak } from "@react-keycloak/web";
interface LoginButtonsSectionProps {}

export const LoginButtonsSection: React.FC<LoginButtonsSectionProps> = () => {

  const { keycloak } = useKeycloak();

  return (
    <VStack spacing={5} alignItems="center">
      <CustomButton
        logoSrc={IconImage}
        text="Zaloguj się z CyberID"
        backgroundColor="#E2E1E1"
        textColor="#CB004B"
        py="8px"
        minHeight="55px"
        minWidth="350px"
        onClick={()=>keycloak.login()}
      />

    </VStack>
  );
  /**<CustomButton
    text="Zaloguj się"
    backgroundColor="#000000"
    textColor="#FFFFFF"
    py="8px"
    minHeight="55px"
    minWidth="350px"
  />
  <Link to="/rejestracja" tabIndex={-1}>
    <CustomButton
      text="Zarejestruj się >>"
      backgroundColor="transparent"
      textColor="accent"
      py="8px"
      minHeight="55px"
      minWidth="350px"
    />
  </Link>*/
};
