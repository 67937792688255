import { Button, IconButton, VStack } from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { brandModel } from "../../../models/BrandModel";
import { onIconWithListClick } from "../helpers";
import { MAIN_MENU_ITEMS } from "../constants";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { DropdownWrapper } from "./DropdownWrapper";

export const HamburgerMenu: React.FC = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentBrand } = brandModel;
  const listRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);

  const frame = document.querySelector('#main-frame') as HTMLIFrameElement | null;

  return (
    <VStack>
      <IconButton
        aria-label={"label"}
        icon={<MenuIcon fill={isOpen ? "black" : "white"} />}
         onClick={()=>{currentBrand.id === '7' ?  frame?.contentWindow?.postMessage('open-menu', currentBrand.app_url):  onIconWithListClick(setIsOpen, listRef, iconRef)}}
      //  onClick={()=>onIconWithListClick(setIsOpen, listRef, iconRef)} //menu bedzie inne w zaleznosci od wybranej apki, moze lepiej tu zrobic globalnego refa niz queryselector ale to musze cie zapytac w tym mobx
        //w pliku mainview.tsx tez wysylam cos takiego,
        //
        ref={iconRef}
        bg="transparent"
        border={0}
        w="fit-content"
        h="fit-content"
      />
      {/**isOpen && (
        <DropdownWrapper listRef={listRef}>
          <VStack gap="14px">
            {MAIN_MENU_ITEMS.map(menuItem => (
              <Button variant="menu" key={menuItem.href} as="a" href={menuItem.href} bg="primary">
                {menuItem.label}
              </Button>
            ))}
          </VStack>
        </DropdownWrapper>
      )*/}
    </VStack>
  );
});
