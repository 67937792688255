import { action, makeObservable, observable } from "mobx";
import { BrandMenuItemType, BRAND_MENU_ITEMS } from "../constants/brand";
import { Model } from "./Model";

export class BrandModel extends Model {
  currentBrand: BrandMenuItemType = BRAND_MENU_ITEMS[0];
  constructor() {
    super();

    makeObservable(this, {
      currentBrand: observable,
      setBrand: action.bound
    });
  }

  setBrand(brand: BrandMenuItemType) {
    this.currentBrand = brand;
    console.log(this.currentBrand);
  }

  override async onInit() {}
}

export const brandModel = new BrandModel();
