import { VStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Header } from "../Header/Header";

interface LayoutProps {
  children: ReactNode;
  isMenu?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ children, isMenu }) => (
  <VStack h="100%" minH="100vh" justify={"stretch"} spacing='0'>
    <Header />
    <VStack  h="100%" w='100%'>
      {children}
    </VStack>
  </VStack>
);
