import { BoxProps, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface DropdownWrapperProps extends BoxProps {
  children: ReactNode;
  listRef: React.RefObject<HTMLDivElement>;
  isLeft?: boolean;
  bottomElement?: ReactNode;
}

export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  children,
  listRef,
  isLeft,
  bottomElement,
  ...rest
}) => {
  return (
    <Flex
      pos="absolute"
      top="100%"
      right={isLeft ? undefined : 0}
      left={isLeft ? 0 : undefined}
      ref={listRef}
      w="460px"
      zIndex={1}
      bg="black"
      px="60px"
      py="30px"
      borderRadius="0px 0px 15px 15px"
      direction="column"
      {...rest}
    >
      <Flex direction="column" overflowY="auto">
        {children}
      </Flex>
      {bottomElement}
    </Flex>
  );
};
