import { Flex, HStack } from "@chakra-ui/react";
import React from "react";
import { LAYOUT_CONTENT_WIDTHS } from "../../utils/constants";
import { BrandSelect } from "./components/BrandSelect";
import { HamburgerMenu } from "./components/HamburgerMenu";
import { OrganisationSelect } from "./components/OrganisationSelect";
import { UserMenu } from "./components/UserMenu";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = ({}) => {
  return (
    <Flex
      background="primary"
      w="100vw"
      borderBottomRadius="15px"
      boxShadow="standard"
      justifyContent={"center"}
      pos="relative"
      maxW="100%"
      h="100px"
    >
      <Flex maxW={LAYOUT_CONTENT_WIDTHS} h="100%" w="100%" align="center" justify="Space-between" position="relative">
        <BrandSelect />
        <HStack>

          <UserMenu />
          <HamburgerMenu />
        </HStack>
      </Flex>
    </Flex>
  );
  /**  <OrganisationSelect />*/
};
