import { makeObservable } from "mobx";
import { Model } from "../../models/Model";

export class MainViewModel extends Model {
  constructor() {
    super();

    makeObservable(this, {});
  }

  override async onInit() {}
}
