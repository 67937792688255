import { Divider, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrowDown.svg";
import { BrandMenuItemType, BRAND_MENU_ITEMS } from "../../../constants/brand";
import { brandModel } from "../../../models/BrandModel";
import { onIconWithListClick } from "../helpers";
import { DropdownWrapper } from "./DropdownWrapper";

interface BrandSelectProps {}

export const BrandSelect: React.FC<BrandSelectProps> = observer(({}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { setBrand, currentBrand } = brandModel;

  const listRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);

  const onElementClick = (item: BrandMenuItemType) => {
    setBrand(item);
    setIsOpen(false);
  };

  return (
    <VStack>
      <HStack as="button" onClick={() => onIconWithListClick(setIsOpen, listRef, iconRef)} ref={iconRef} gap="30px">
        <Image src={currentBrand.logo} alt="logo" style={{maxHeight: '60px'}}/>
        <ArrowDown fill={isOpen ? "black" : "white"} />
      </HStack>
      {isOpen && (
        <DropdownWrapper isLeft listRef={listRef} px="30px" py="20px">
          {BRAND_MENU_ITEMS.map((item, index) => (
            <>
              <HStack as="button" gap="16px" py="12px" onClick={() => onElementClick(item)}>
                <Image src={item.sygnet} alt="sygnet" style={{maxWidth: '40px'}}/>
                <Text color="white">{item.name}</Text>
              </HStack>
              {index !== BRAND_MENU_ITEMS.length - 1 && <Divider borderColor="divider" />}
            </>
          ))}
        </DropdownWrapper>
      )}
    </VStack>
  );
});
