import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import getTheme from "./styles/theme";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/Router";
import { brandModel } from "./models/BrandModel";
import { observer } from "mobx-react-lite";
import "./styles/globals.css";


export const App = observer(() => {

  const { currentBrand } = brandModel;


  return (

      <ChakraProvider theme={getTheme(currentBrand.color)}>
        <RouterProvider router={router} />
      </ChakraProvider>

  );
});
