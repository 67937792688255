import { Box, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { Logo } from "./components/Logo";
import { LoginButtonsSection } from "./components/LoginButtonsSection";
import PageWrapper from "./components/PageWrapper";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

interface LoginViewProps {}

export const LoginView: React.FC<LoginViewProps> = observer(({}) => {

  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {

    console.log(keycloak)
    if(keycloak.authenticated){
      navigate('/');
    }
  });

  return (
  <PageWrapper>
    <VStack align="center" alignSelf="flex-start" ml="12px" mt="12px" flexGrow={1}>
      <Box mt="100px">
        {" "}
        <Logo />
      </Box>
      <Box mt="45px">
        <LoginButtonsSection />
      </Box>
    </VStack>
  </PageWrapper>
);
});
