type SetIsOpenType = React.Dispatch<React.SetStateAction<boolean>>;

function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`);
  }
}

const checkIsListClosing = (
  event: MouseEvent,
  setIsOpen: SetIsOpenType,
  listRef: React.RefObject<HTMLDivElement>,
  iconRef: React.RefObject<SVGSVGElement | HTMLButtonElement>
) => {

  assertIsNode(event.target);
  const isOnBlurAction = !listRef.current?.contains(event.target) && !iconRef.current?.contains(event.target);

  if (isOnBlurAction) {
    setIsOpen(false);
    document.removeEventListener("mousedown", e => checkIsListClosing(e, setIsOpen, listRef, iconRef));
  }
};

export const onIconWithListClick = (
  setIsOpen: SetIsOpenType,
  listRef: React.RefObject<HTMLDivElement>,
  iconRef: React.RefObject<HTMLButtonElement>,
) => {

  setIsOpen(isOpen => {
    if (isOpen) {
      document.removeEventListener("mousedown", e => checkIsListClosing(e, setIsOpen, listRef, iconRef));
    } else {
      document.addEventListener("mousedown", e => checkIsListClosing(e, setIsOpen, listRef, iconRef));
    }

    return !isOpen;
  });


};
