import { FC } from "react";
import { Model } from "../models/Model";

export type ConstructorOf<T> = new (
  p1?: any,
  p2?: any,
  p3?: any,
  p4?: any
) => T;

export function withModel<T extends Model, P extends Object = {}>(
  Component: FC<{ model: T } & P>,
  Model: ConstructorOf<T>
) {
  const model = new Model();
  model.onInit();

  return (props: P) => {
    return <Component model={model} {...props} />;
  };
}
