export type BrandMenuItemType = {
  id: string;
  name: string;
  color: string;
  logo: string;
  sygnet: string;
  label: string;
  app_url: string;
};

export const BRAND_MENU_ITEMS = [
/**  {
    id: "1",
    name: "ivotingFree",
    color: "#16BECF",
    logo: "LOGO_IVOTING_FREE.svg",
    sygnet: "IVOTING_FREE_SYGNET.svg",
    label: "iVoting Free",
    app_url: 'https://app.ivoting.pl/'
  },*/
  {
    id: "2",
    name: "ivotingBusiness",
    color: "#EBB134",
    logo: "LOGO_IVOTING_BUSINESS.svg",
    sygnet: "IVOTING_BUSINESS_SYGNET.svg",
    label: "iVoting Business",
    app_url: 'https://ivb3.ivoting.pl'
  },
  /**{
    id: "3",
    name: "ivotingGov",
    color: "#977460",
    logo: "LOGO_IVOTING_GOV.svg",
    sygnet: "IVOTING_GOV_SYGNET.svg",
    label: "iVoting GOV",
    app_url: 'HTTPS://TTEST.COM'
  },
  {
    id: "4",
    name: "ivotingNgo",
    color: "#237760",
    logo: "LOGO_IVOTING_NGO.svg",
    sygnet: "IVOTING_NGO_SYGNET.svg",
    label: "iVoting NGO",
    app_url: 'HTTPS://TTEST.COM'
  },
  {
    id: "5",
    name: "ivotingUniversity",
    color: "#BC1F4C",
    logo: "LOGO_IVOTING_UNIVERSITY.svg",
    sygnet: "IVOTING_UNIVERSITY_SYGNET.svg",
    label: "iVoting University",
    app_url: 'HTTPS://TTEST.COM'
  },
  {
    id: "6",
    name: "ivotingPublicOpinion",
    color: "#912690",
    logo: "LOGO_IVOTING_PUBLIC_OPINION.svg",
    sygnet: "IVOTING_PUBLIC_OPINION_SYGNET.svg",
    label: "iVoting Public Opinion",
    app_url: 'HTTPS://TTEST.COM'
  },
  {
    id: "8",
    name: "CyberID",
    color: "rgb(187, 23, 61)",
    logo: "LOGO_IVOTING_PUBLIC_OPINION.svg",
    sygnet: "IVOTING_PUBLIC_OPINION_SYGNET.svg",
    label: "iVoting Public Opinion",
    app_url: 'HTTPS://newdev.cyberid.app'
  },*/
  {
    id: "7",
    name: "ivotingBlockchain",
    color: "#222426",
    logo: "LOGO_IVOTING_BLOCKCHAIN.svg",
    sygnet: "IVOTING_BLOCKCHAIN_SYGNET.svg",
    label: "iVoting Blockchain",
    app_url: 'https://dev.blockchain.ivoting.pl'
  }
];
